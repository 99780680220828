/* Loading spinner div */
.integration-spinner {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Parent div */
.integration-parent {
  min-height: 100vh;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.integration-parent > div {
  flex: 1;
}
.integration-parent .fintesa-terms a {
  font-weight: bold;
}

/* The div that contains product info */
.integration-parent .firstChild {
  background-color: rgb(84, 105, 212);
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding: 10rem 5rem 5rem;
}
.integration-parent .firstChild > div:first-of-type {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 1rem;
}
.integration-parent .firstChild table {
  margin-top: 2rem;
  width: 95%;
  border-top: 1px solid white;
  width: clamp(15rem, 25rem, 100%);
}
.integration-parent .firstChild table tr td:first-of-type {
  padding-left: 0.5rem;
}
.integration-parent .firstChild table tr td:nth-of-type(2) {
  text-align: center;
}
.integration-parent .firstChild table tr td:last-of-type {
  text-align: end;
  padding-right: 0.5rem;
}
.integration-parent .firstChild table tbody tr:first-of-type td {
  padding-top: 0.7rem;
}
.integration-parent .firstChild table tbody tr:last-of-type td {
  padding-bottom: 0.5rem;
}
/* .integration-parent .firstChild table th {
  font-size: 1.3rem;
  padding-left: 0.3rem;
} */
.integration-parent .firstChild table tfoot {
  border-top: 1px solid white;
  font-size: 1.2rem;
  padding-left: 0.3rem;
}
.integration-parent .firstChild img {
  width: 6rem;
  height: 6rem;
  border-radius: 50%;
}
.integration-parent .firstChild .fintesa-terms {
  margin-bottom: 1em;
}

/* The div that contains payment element */
.integration-parent .secondChild {
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
}
.integration-parent .secondChild .api-checkout-form-container {
  box-sizing: border-box;
  padding: 2em;
  width: 430px;
  max-width: 100%;
  border-radius: 0.5em;
  box-shadow: 0px 0px 0.5px rgba(50, 50, 93, 0.1), 0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
}
.integration-parent .secondChild .api-checkout-form-container form {
  max-width: 100%;
}
.integration-parent .secondChild .api-checkout-form-container input#card-name,
.integration-parent .secondChild .api-checkout-form-container input#email {
  max-width: 100%;
}
.integration-parent .secondChild .api-checkout-form-container .button-container {
  display: flex;
  justify-content: center;
  margin-block: 0.6rem;
}
.integration-parent .secondChild .api-checkout-form-container .button-container button {
  width: clamp(200px, 90%, 400px);
}
.integration-parent .secondChild .api-checkout-form-container form .error-message {
  color: red;
  text-align: center;
}
.integration-parent .secondChild .api-checkout-form-container form .termsconditions_checkbox {
  margin-top: 0.5rem;
}
.integration-parent .secondChild .fintesa-terms {
  display: none;
}

/* Subscription Styling Start */
.integration-parent .subscription-details {
  text-align: center;
}
.integration-parent .subscription-details h3 {
  font-size: 2rem;
  margin-top: 2rem;
}
.integration-parent .subscription-details p:first-of-type {
  font-size: 1.5rem;
}
/* Subscription Styling End */

@media (max-width: 850px) {
  .integration-parent {
    flex-direction: column;
  }
  .integration-parent .firstChild {
    justify-content: flex-start;
    padding: 2rem;
  }
  /* Hide terms from firstChild class (horizontal)  */
  .integration-parent .firstChild .fintesa-terms {
    display: none;
  }
  /* Show terms from secondChild class (vertical)  */
  .integration-parent .secondChild .fintesa-terms {
    display: block;
    color: hsl(0deg 3% 7% / 48%);
    text-align: center;
  }
}
