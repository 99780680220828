.body {
  text-align: center;
}

.price {
  font-size: calc(1.324rem + 0.9vw);
  font-weight: 500;
}

@media (min-width: 1200px) {
  .price{
    font-size: 2rem;
  }
}