.footer {
  text-align: center;
}

.footer div {
  font-weight: 500;
}
@media (max-width: 850px) {
  .footer {
    display: none;
  }
}