.header {
  display: flex;
  justify-content: center;
  align-items: center;
}
.header img {
  width: clamp(50px, 96px, 100%);
}

@media (max-width: 850px) {
  .header {
    margin-top: 2rem;
  }
}