.container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  flex: 1;
  max-width: 100%;
}
.container > div {
  max-width: 95%;
}