/* Global Styling */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.payment-body {
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background-color: burlywood;
}

#payment-form {
  width: 30vw;
  min-width: 500px;
  align-self: center;
  box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
              0px 2px 5px 0px rgba(50, 50, 93, 0.1),
              0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
  border-radius: 7px;
  padding: 40px;
  background-color: #fff;
}

.error-toast {
  position: absolute;
  top: 0;
  height: 30px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  background-color: rgb(230, 159, 159);
  border: 0;
}

.payment_intent_style {
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 16px;
  width: 100%;
  overflow-x: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.payment_intent_style .form_payments {
  width: 100%;
  height: 100vh;
  background-color: #fff;
  padding: 20px;

  
}

.background_element {
  background-color: #5469d4;
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.background_element .header_payments_text .logo {
  height: 6rem;
  width: 6rem;
  object-fit: cover;
}

.spinner,
.spinner:before,
.spinner:after {
  border-radius: 50%;
}

.spinner {
  color: #ffffff;
  font-size: 22px;
  width: 20px;
  height: 20px;
  position: relative;
  margin: auto;
  box-shadow: inset 0 0 0 2px;
  animation: loading 2s infinite ease;
}

.spinner:before,
.spinner:after {
  content: '';
  position: absolute;
}

.spinner:before {
  width: 10.4px;
  height: 20.4px;
  background: #5469d4;
  border-radius: 20.4px 0 0 20.4px;
  transform-origin: 10.4px 10.2px;
  animation: loading 2s infinite ease 1.5s;
}

.spinner:after {
  width: 10.4px;
  height: 10.2px;
  background: #5469d4;
  border-radius: 0 10.2px 10.2px 0;
  transform-origin: 0px 10.2px;
}

.container_payments {
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 100vh;
}

.container_success-checkmark_ {
  text-align: center;
  padding: 1.5em;
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: #fff;
}

.success-checkmark {
  width: 80px;
  height: 115px;
  margin: auto;
}

.success-checkmark .check-icon {
  width: 80px;
  height: 80px;
  position: relative;
  border-radius: 50%;
  border: 4px solid #4CAF50;
}

.success-checkmark .check-icon .icon-line {
  height: 5px;
  background-color: #4CAF50;
  display: block;
  border-radius: 2px;
  position: absolute;
  z-index: 10;
}

.success-checkmark .check-icon .icon-line.line-tip {
  top: 46px;
  left: 14px;
  width: 25px;
  transform: rotate(45deg);
  animation: icon-line-tip 0.75s;
}

.success-checkmark .check-icon .icon-line.line-long {
  top: 38px;
  right: 8px;
  width: 47px;
  transform: rotate(-45deg);
  animation: icon-line-long 0.75s;
}

@keyframes loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes icon-line-tip {
  0% {
    width: 0;
    left: 1px;
    top: 19px;
  }
  100% {
    width: 25px;
    left: 14px;
    top: 45px;
  }
}

@keyframes icon-line-long {
  0% {
    width: 0;
    right: 46px;
    top: 54px;
  }
  100% {
    width: 47px;
    right: 8px;
    top: 38px;
  }
}

/* Responsive Styling */
@media only screen and (max-width: 600px) {
  #payment-form {
    width: 80vw;
    min-width: unset;
    padding: 20px;
  }
}

@media only screen and (max-width: 520px) {
  #payment-form {
    margin: 0;
  }
}
