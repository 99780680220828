.container {
  box-sizing: border-box;
  padding: 2em;
  width: 430px;
  max-width: 100%;
  border-radius: 0.5rem;
  box-shadow: 0px 0px 0.5px rgba(50, 50, 93, 0.1), 0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
}
.container form {
  max-width: 100%;
}
.button-container {
  display: flex;
  justify-content: center;
  margin-block: 0.6rem;
}
.button-container button {
  width: clamp(200px, 90%, 400px);
}
.error-message {
  color: red;
  text-align: center;
}