/* html{
  height: 100vh;
} */
* {
  margin: 0;
  padding: 0;
  /* overflow-y: scroll; */
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: rgb(255, 255, 255); */
}

/* payment_ style  */
.payment_intent_style {
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  /* height: 100vh; */
  margin: 0;
  padding: 0;
  width: 100%;
  overflow: auto;
  overflow-x: hidden;
}

.payment_intent_style form {
  margin: 25vh 20vh;
}

.payment_intent_style button {
  background: #5469d4;
  font-family: Arial, sans-serif;
  color: #ffffff;
  border-radius: 4px;
  border: 0;
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  display: block;
  transition: all 0.2s ease;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
  width: 100%;
  margin-top: 15px;
}

.payment_intent_style button:hover {
  filter: contrast(115%);
}

.payment_intent_style button:disabled {
  opacity: 0.5;
  cursor: default;
}

/* spinner/processing state, errors */
.payment_intent_style .spinner,
.payment_intent_style .spinner:before,
.payment_intent_style .spinner:after {
  border-radius: 50%;
}

.payment_intent_style .spinner {
  color: #ffffff;
  font-size: 22px;
  text-indent: -99999px;
  margin: 0px auto;
  position: relative;
  width: 20px;
  height: 20px;
  box-shadow: inset 0 0 0 2px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

.payment_intent_style .spinner:before,
.payment_intent_style .spinner:after {
  position: absolute;
  content: '';
}

.payment_intent_style .spinner:before {
  width: 10.4px;
  height: 20.4px;
  background: #5469d4;
  border-radius: 20.4px 0 0 20.4px;
  top: -0.2px;
  left: -0.2px;
  -webkit-transform-origin: 10.4px 10.2px;
  transform-origin: 10.4px 10.2px;
  -webkit-animation: loading 2s infinite ease 1.5s;
  animation: loading 2s infinite ease 1.5s;
}

.payment_intent_style .spinner:after {
  width: 10.4px;
  height: 10.2px;
  background: #5469d4;
  border-radius: 0 10.2px 10.2px 0;
  top: -0.1px;
  left: 10.2px;
  -webkit-transform-origin: 0px 10.2px;
  transform-origin: 0px 10.2px;
  -webkit-animation: loading 2s infinite ease;
  animation: loading 2s infinite ease;
}

@keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}


/* form payments */
.payment_intent_style .col {
  flex: none;
}

.payment_intent_style .App {
  text-align: inherit;
}

.payment_intent_style .container_payments {
  display: flex;
  height: 100vh;
  width: 100%;
  align-items: center;
  padding-bottom: 0%;
}

.alert-message {
 margin-inline:9rem;
}


.payment_intent_style #payment-form {
  width: auto;
  min-width: 400px;
  box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1), 0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
  border-radius: 7px;
  padding: 40px;
  margin: 15%;
}

.payment_intent_style .form_payments {
  width: 50%;
  height: 100%;
   overflow-y: auto; 
  overflow-x: hidden; 
}

/* @media (min-width: 1200px){
.payment_intent_style .form_payments{
  overflow-y: auto;
}
} */
.payment_intent_style .background_element {
  background-color: #5469d4;
  height: 100%;
}

.payment_intent_style .__PrivateStripeElement {
  margin: 15px 0px !important;
}

.payment_intent_style .text_payments {
  display: flex;
  justify-content: center;
  margin: 16% auto;
}

.payment_intent_style .header_payments_text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.payment_intent_style .header_payments_text img {
  width: 35px;
  height: 35px;
  object-fit: cover;
}

.payment_intent_style .header_payments_text .updated_text_payments {
  color: hsla(0, 0%, 100%, .6);
  font-size: 16px;
  font-weight: 500;
}

.payment_intent_style .header_payments_text .price_text_payments {
  color: hsla(0, 0%, 100%, 1);
  font-size: 36px;
  font-weight: 600;
  margin: 0;
}

.payment_intent_style .header_payments_text .desc_text_payments {
  margin-top: 2rem;
  color: hsla(0, 0%, 100%, .6);
  font-size: 14px;
  font-weight: 500;
  max-width: 80%;
  overflow: auto;
  max-height: 110px;
  overflow-x: hidden;
}

.payment_intent_style .header_payments_text .desc_text_payments::-webkit-scrollbar {
  background-color: transparent;
}

.payment_intent_style .header_payments_text .desc_text_payments::-webkit-scrollbar-track {
  background-color: hsla(0, 0%, 100%, 0.5);
}

.payment_intent_style .header_payments_text .desc_text_payments::-webkit-scrollbar-thumb {
  background-color: hsla(0, 0%, 100%, 0.7);
  /* outline: 1px solid slategrey; */
}

.payment_intent_style .footer_payments_text {
  position: absolute;
  top: 90%;
  font-size: 14px;
  font-weight: 400;
  color: hsla(0, 0%, 100%, .5);
}

.payment_intent_style .terms_footer_payments_text {
  margin-right: 10px;
}

.payment_intent_style .footer_payments_text span a {
  color: hsla(0, 0%, 100%, .4);
  font-weight: 600;
}

.payment_intent_style .termsconditions_checkbox {
  margin-top: 20px;
}

.payment_intent_style .termsconditions_checkbox label {
  margin-left: 5px;
  color: rgb(179 179 179);
}

.payment_intent_style .termsconditions_checkbox label a {
  color: #0000ff7a;
}

.payment_intent_style .footer_payment_elemnts {
  display: none;
  justify-content: center;
  margin-top: 50px;
  font-size: 12px;
  color: hsla(0, 0%, 10%, .5);
  font-weight: 400;
}

.payment_intent_style .footer_payment_elemnts span {
  margin: 0 5px;
}

.payment_intent_style .footer_payment_elemnts span a {
  color: hsl(0deg 3% 7% / 48%);
  font-weight: 700;
}

/* media  */
@media (min-width:1200px) and (max-width: 1550px) {

  .payment_intent_style #payment-form {
    width: 100px;
    box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1), 0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
    border-radius: 7px;
    padding: 40px;
  }
}





@media screen and (max-width: 1199px) {
  .container_success-checkmark_ {
    height: 50vh !important;
  }

  .payment_intent_style {
    overflow: visible;
  }

  

  .payment_intent_style .header_payments_text .desc_text_payments {
    margin-bottom: -10rem;
  }

  .payment_intent_style .row {
    --bs-gutter-x: 0;
    --bs-gutter-y: 0;
    display: block;
    margin: 0;
    margin-top: 0;
    margin-right: 0;
    margin-left: 0;
  }

  .payment_intent_style .container_payments {
    display: flex;
    width: 100%;
    align-items: center;
    align-content: space-around;
    flex-wrap: wrap;
  }

  .payment_intent_style .text_payments {
    margin: 16% auto 0;
    align-items: center;
  }

  .payment_intent_style .background_element {
    background-color: #5469d4;
    width: 100%;
    display: flex;
    align-items: center;
  }

  .payment_intent_style .footer_payments_text {
    display: none;
  }

  .payment_intent_style .form_payments {
    width: 100%;
    height: auto;
    background-color: #fff;
  }

  .payment_intent_style form {
    margin: 7vh 7vh;
  }

  .payment_intent_style .header_payments_text img {
    width: 35px;
    height: 35px;
    object-fit: cover;
    margin-top: 0;
  }

  .payment_intent_style .footer_payment_elemnts {
    display: flex;
    justify-content: center;
    margin-top: 50px;
  }

}


/* payment_ style  */


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* product in Header */
[data-letters]:before {
  content: attr(data-letters);
  display: block;
  font-size: 1em;
  width: 2.5em;
  height: 2.5em;
  line-height: 2.5em;
  text-align: center;
  border-radius: 50%;
  margin-right: 1em;
  color: white;
  /* background-color: gray; */
  box-shadow: 0 1px 5px 0 grey;
  cursor: 'pointer';
  font-weight: 600;
}

[data-letters-MC]:before {
  content: attr(data-letters-MC);
  display: block;
  font-size: 1em;
  width: 2.5em;
  height: 2.5em;
  line-height: 2.5em;
  text-align: center;
  border-radius: 50%;
  margin-right: 1em;
  color: white;
  background-color: #8085e3;
  box-shadow: 0 1px 5px 0 #8085e3;
  cursor: 'pointer';
  font-weight: 600;
}

/* Account Info link */
.Header_link {
  background-color: #fff;
  box-shadow: 0 2px 4px rgb(50 50 93 / 10%);
  text-decoration: none;
  color: #02203c;
  border-radius: 5px;
  border-color: none;
}

.Header_link_span {
  align-items: center;
  display: flex;
  justify-content: center;
  white-space: nowrap;
  width: 100%;
  font-size: 14px;
  text-align: center;
  padding: 10px 0 10px 0;
}

.Header_link:hover {
  background-color: rgba(235, 232, 232, 0.849);
  text-decoration: none;
  color: #02203c;
}

/* to hidden description from product table */
.ellipsis {
  max-width: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}


/* PaymentStyle.js */
.parent {
  display: flex;
  border: 1px solid #e3e8ee;
  height: 95vh;
  position: relative;
  top: 2.5vh;
  border-radius: 2%;
}

.parent>.inputs {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-right: 1px solid #e3e8ee;
  padding: 0 1em;
}

.parent>.inputs>.inputsForm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1.5em;
}

.parent>.inputs>.inputsForm>.colorInputDiv {
  display: flex;
  justify-content: space-between;
}

.parent>.inputs>.inputsForm>.colorInputDiv .colorAndValue {
  display: flex;
  flex-direction: column;
}

.parent>.inputs>.inputsForm>.colorInputDiv .colorAndValue .inputVal {
  display: flex;
  align-items: center;
}

.parent>.inputs>.inputsForm>.colorInputDiv .colorInput {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 2em;
  height: 2em;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.parent>.inputs>.inputsForm>.colorInputDiv .colorInput::-webkit-color-swatch {
  border-radius: 0.2em;
  border: none;
}

.parent>.inputs>.inputsForm>.colorInputDiv .colorInput::-moz-color-swatch {
  border-radius: 0.2em;
  border: none;
}

.parent .screen {
  /* background-color: #f6f8fa; */
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.parent .preview-toggle {
  padding: 1em;
  align-self: flex-start;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.parent .preview {
  height: 70%;
  width: 50%;
}

.parent .preview .info {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
  padding: 1em;
  flex-grow: 1;
}

.parent .preview>.card {
  border: 3px solid #e3e8ee;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: center;
}

.parent .preview>.card.horizontal {
  grid-template-columns: 1fr;
  max-width: 400px;
  margin-inline: auto;
  text-align: center;
}

.header_payments_text .logo {
  width: 80px;
  border-radius: 50%;
  aspect-ratio: 1/1;
  object-fit: cover;
}

.parent .preview .element-preview {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 1em;
  background-color: #ffffff;
}

.parent .preview .element-preview button {
  width: 60%;
  border: none;
}

.login-form {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: white;
  padding: 0 16px;
}

.login-form>div {
  width: 100%;
  /* height: 80%; */
  max-width: 400px;
  padding: 3rem 2rem;
  border: 1px solid rgb(206, 212, 218);
  border-radius: 0.5rem;
  background-color: #fbfbfb;
  /* height: 80%; */
}

.login-form div h2 {
  text-align: center;
  margin: 1rem 0 2rem;
}

.login-form div * {
  width: 100%;
  border: none;
  padding: 0;
}

.login-form div * input {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid rgb(206, 212, 218);
}

.login-form div button {
  padding: 0.5rem;
  margin-top: 2rem;
  background-color: #5469d4;
}

.update-payment-method-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
}

.update-payment-method-container .product-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.simple-black-shadow {
  box-shadow: 1px 3px 9px 1px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 1px 3px 9px 1px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 1px 3px 9px 1px rgba(0, 0, 0, 0.5);
}

/* display:'flex', minHeight:'100vh', width:'100%' */
.fintesa-plans-container {
  display: grid;
  min-height: 100vh;
  width: 100%;
  grid-template-columns: repeat(2, 1fr);
}

.background_element .header_payments_text .logo {
  height: 6rem;
  width: 6rem;
  object-fit: cover;
}


@media (max-width: 700px) {
  .fintesa-plans-container {
    flex-direction: column;
    grid-template-columns: 1fr;
  }
  .payment_intent_style #payment-form {
  margin: auto;
  }
}
@media only screen and (max-width: 360px) {
  .payment_intent_style #payment-form {
    padding-inline-start: 10px;
    padding-inline-end: 70px;
  }
  
}
@media only screen and (max-width: 320px) {
  .payment_intent_style #payment-form {
    padding-inline-start: 10px;
    padding-inline-end: 90px;
  }
}



@media only screen and (max-width: 280px) {
  .payment_intent_style #payment-form {
    padding-inline-start: 10px;
    padding-inline-end: 130px;
  }
}
@media only screen and (max-width: 1550px) {
  .alert-message {
    margin-inline-start:5rem;
    margin-inline-end: 12rem;
    

   }
   
   
}
@media only screen and (max-width: 1200px) {
  .alert-message {
    margin-inline-start:5rem;
    margin-inline-end: 5rem;
    

   }
   
   
}
@media only screen and (max-width: 666px) {
  .alert-message {
    margin: auto;
    

   }
   
   
}
